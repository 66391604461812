import React from 'react';
import Banner from '../../../components/molecules/banner/Banner';
import Header from '../../../components/organisms/header/Header';
import PlanFinancement from '../../../components/organisms/forms/planFinancement/PlanFinancement';
import Footer from '../../../components/organisms/footer/Footer';

function PlanFinancementPage({ title }) {
    document.title = title;

    return (
        <>
            <Header />
            <Banner
                title="Plan de financement"
                description="Réalisez et sauvegardez vos plans de financement en quelques clics"
            />
            <PlanFinancement />
            <Footer />
        </>
    );
}

export default PlanFinancementPage;
