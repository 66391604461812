import './Carousel.css';
import React, { useEffect, useState, useRef } from 'react';
import { useWindowSize } from '../../../utils/Utils';
import Arrow from '../../atoms/icons/arrow/Arrow';

function Carousel({
    Element,
    data,
    title,
    color = 'var(--blue-dark',
    displayedItemsArray = { 0: 1, 800: 2, 1200: 3 },
    slideNumber = 3,
}) {
    const [content, setContent] = useState([]);
    const [stopper, setStopper] = useState(false);
    const [displayedItemsCount, setDisplayedItemsCount] = useState(1);
    const [translate, setTranslate] = useState(-1);
    const [slideLength, setSlideLength] = useState(slideNumber);
    const [margin, setMargin] = useState(0);
    const [dataLength, setDataLength] = useState(data.length);
    const carouselItems = useRef(null);
    const [width, height] = useWindowSize();

    useEffect(() => {
        const tmp =
            displayedItemsArray[
                Number(
                    Object.keys(displayedItemsArray)
                        .filter(element => element < width)
                        .reverse()[0]
                )
            ];
        setDisplayedItemsCount(tmp);
        if (tmp < slideLength) {
            setSlideLength(tmp);
        }
    }, [width]);

    useEffect(() => {
        let tmp = [];
        const marginTmp = displayedItemsCount + slideLength - 1;

        setDataLength(data.length);
        setMargin(displayedItemsCount + slideLength - 1);

        data.forEach(element => tmp.push(element));

        if (tmp.length >= displayedItemsCount) {
            for (let i = 0; i < marginTmp; i++) {
                tmp.push(tmp[i]);
            }
            for (let i = 0; i < marginTmp; i++) {
                tmp.unshift(tmp[data.length - 1]);
            }
            setContent(tmp);
            carouselItems.current.style['justify-content'] = 'flex-start';
            if (translate < 1) setTranslate(marginTmp);
        } else {
            setContent(data);
            carouselItems.current.style['justify-content'] = 'center';
            setTranslate(0);
        }

        setTimeout(() => {
            carouselItems.current.style.transition = 'transform 0.3s';
        }, 10);
    }, [data, margin, displayedItemsCount]);

    useEffect(() => {
        if (content.length >= displayedItemsCount) {
            if (translate + displayedItemsCount <= margin) {
                setTimeout(() => {
                    carouselItems.current.style.transition = 'none';
                    setTranslate(translate + dataLength);

                    setTimeout(() => {
                        carouselItems.current.style.transition = 'transform 0.3s';
                    }, 10);
                }, 300);
            } else if (translate >= margin + dataLength) {
                setTimeout(() => {
                    carouselItems.current.style.transition = 'none';
                    setTranslate(translate - dataLength);

                    setTimeout(() => {
                        carouselItems.current.style.transition = 'transform 0.3s';
                    }, 10);
                }, 300);
            }
        }
    }, [translate]);

    function incTranslate(e) {
        e.preventDefault();

        if (!stopper && data.length > displayedItemsCount) {
            setTranslate(translate + slideLength);
            setStopper(true);
            setTimeout(() => {
                setStopper(false);
            }, 300);
        }
    }

    function decTranslate(e) {
        e.preventDefault();

        if (!stopper && data.length > displayedItemsCount) {
            setTranslate(translate - slideLength);
            setStopper(true);
            setTimeout(() => {
                setStopper(false);
            }, 300);
        }
    }

    return (
        <section
            style={{
                '--color': color,
                '--displayed-items': displayedItemsCount,
                '--translate': translate,
            }}>
            <h3 className="carousel-title">{title}</h3>
            <div className="carousel-container">
                <div className="carousel-arrow">
                    {data.length > displayedItemsCount && <Arrow color={color} angle="180deg" onClick={decTranslate} />}
                </div>
                <div className="carousel-content">
                    <div className="carousel-items" ref={carouselItems}>
                        {content.map((element, key) => (
                            <Element data={element} key={key} />
                        ))}
                    </div>
                </div>
                <div className="carousel-arrow-right">
                    {data.length > displayedItemsCount && <Arrow color={color} onClick={incTranslate} />}
                </div>
            </div>
        </section>
    );
}

export default Carousel;
