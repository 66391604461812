import { Link } from 'react-router-dom';
import './UnfilledButton.css';
import React from 'react';

function UnfilledButton({
    borderColor = 'var(--blue-focus)',
    fontSize = '1rem',
    padding = '.375rem .75rem',
    children,
    onClick,
    height = '30px',
    borderRadius = '10px',
    hoverColor = 'var(--blue-dark)',
    type = 'button',
    className,
    to,
}) {
    return (
        <>
            {to ? (
                <Link
                    className={'button unfilled-button ' + className}
                    to={to}
                    style={{
                        '--border-color': borderColor,
                        '--font-size': fontSize,
                        '--padding': padding,
                        '--height': height,
                        '--border-radius': borderRadius,
                        '--hover-color': hoverColor,
                    }}
                    onClick={e => {
                        onClick && onClick(e);
                    }}>
                    {children}
                </Link>
            ) : (
                <button
                    className={'invisible-button button unfilled-button ' + className}
                    type={type}
                    style={{
                        '--border-color': borderColor,
                        '--font-size': fontSize,
                        '--padding': padding,
                        '--height': height,
                        '--border-radius': borderRadius,
                        '--hover-color': hoverColor,
                    }}
                    onClick={e => {
                        onClick && onClick(e);
                    }}>
                    {children}
                </button>
            )}{' '}
        </>
    );
}

export default UnfilledButton;
