import './Modal.css';
import React, { useEffect, useState } from 'react';

function Modal({ title, children, isVisible, setIsVisible }) {
    const [isBehind, setIsBehind] = useState(!isVisible);

    function closeModal() {
        setIsVisible(!isVisible);
    }

    useEffect(() => {
        if (isVisible) setIsBehind(false);
        else
            setTimeout(() => {
                setIsBehind(true);
            }, 300);
    });

    return (
        <section className={(isVisible ? '' : 'modal-hidden ') + (isBehind ? 'behind ' : '') + 'modal-container'}>
            <div className="modal-background" onClick={closeModal}></div>
            <div className="modal">
                <div className="modal-header">
                    <p className="modal-title">{title}</p>
                </div>
                <div className="modal-content">{children}</div>
            </div>
        </section>
    );
}

export default Modal;
