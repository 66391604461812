import './Loader.css';
import React, { useEffect } from 'react';

function Loader({ imagePath = 'loader-points.gif', visible }) {
    useEffect(() => {
        if (visible) document.body.classList.add('prevent-scrolling');
        else document.body.classList.remove('prevent-scrolling');
    }, [visible]);

    return (
        <section className={'loader-container ' + (!visible && 'd-none')}>
            <img src={require('../../../assets/loaders/' + imagePath)} alt="loader" />
        </section>
    );
}

export default Loader;
