import './Banner.css';
import React from 'react';
import BackButton from '../../atoms/icons/backButton/BackButton';

function Banner({ title, description }) {
    return (
        <section className="banner">
            <div className="back-button-container">
                <BackButton onClick={() => window.history.back()} />
            </div>
            <div className="banner-content">
                <h1>{title}</h1>
                <hr />
                <p>{description}</p>
            </div>
        </section>
    );
}

export default Banner;
