import React from 'react';
import './Footer.css';
import LogoEmc from '../../atoms/icons/logoEmc/LogoEmc';
import { useNavigate } from 'react-router-dom';
import Heart from '../../atoms/icons/heart/Heart';
import Hr from '../../molecules/hr/Hr';

function Footer() {
    const to = useNavigate();

    return (
        <section className="container">
            <div className="row footer-container">
                <div className="w-100 col mx-sm">
                    <LogoEmc />
                    <p>contact@estimermoncommerce.fr</p>
                    <p>41-43 rue de Cronstadt</p>
                    <p>75015 Paris</p>
                </div>
                <div className="col mx-sm">
                    <h4>Liens légaux</h4>
                    <p className="link" onClick={() => to('/politique-confidentialite')}>
                        Politique de confidentialité
                    </p>
                    <p className="link" onClick={() => to('/mentions-legales')}>
                        Mentions légales
                    </p>
                    <p className="link" onClick={() => to('parametrage-cookies')}>
                        Gérer les cookies
                    </p>
                    <p className="link" onClick={() => to('cgu')}>
                        C.G.U
                    </p>
                    <p className="link" onClick={() => to('cgv')}>
                        C.G.V
                    </p>
                </div>
                <div className="col mx-sm">
                    <h4>Ressources</h4>
                    <p className="link" onClick={() => to('/conseils-pros')}>
                        Conseils de pros
                    </p>
                    <p className="link" onClick={() => to('/outils-pratiques')}>
                        Outils pratiques
                    </p>
                    <p className="link" onClick={() => to('/guides-agents-immos')}>
                        Nos guides pour agents immos
                    </p>
                    <p className="link" onClick={() => to('/simulateur-credit')}>
                        Simulateur de crédit
                    </p>
                    <p className="link" onClick={() => to('/calculateur-cotisations')}>
                        Calculateur de cotisations
                    </p>
                    <p className="link" onClick={() => to('/lexique')}>
                        Lexique
                    </p>
                    <p className="link" onClick={() => to('/')}>
                        Contact
                    </p>
                    <p className="link" onClick={() => to('/mentions-legales')}>
                        F.A.Q
                    </p>
                </div>
            </div>
            <Hr />
            <div className="kweerty">
                With <Heart /> by <a href="kweerty.com">Kweerty</a>
            </div>
        </section>
    );
}

export default Footer;
