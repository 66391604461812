export const phoneValidation = {
    required: 'Ce champ est obligatoire',
    pattern: {
        value: /^[+]?[0-9]{2}[-\s.]?[0-9]{0,2}[-\s.]?[0-9]{2}[-\s.]?[0-9]{2}[-\s.]?[0-9]{2}[-\s.]?[0-9]{2}$/,
        message: 'Numéro de téléphone invalide',
    },
};

export const emailValidation = {
    required: 'Ce champ est obligatoire',
    pattern: {
        value: /^[\w.]+@([\w]+\.)+[\w]{2,4}$/,
        message: 'Adresse mail invalide',
    },
};

export const selectValidation = {
    validate: (value, formValues) => value !== '' || 'Ce champ est obligatoire',
};

export const passwordValidation = {
    required: 'Ce champ est obligatoire',
    pattern: {
        value: /^.{7,}$/,
        message: 'Votre mot de passe doit contenir au moins 7 caractères',
    },
};

export const numberValidation = {
    required: 'Ce champ est obligatoire',
    pattern: {
        value: /^-?[0-9\s]*$/,
        message: "Ce champ n'accepte que les chiffres",
    },
};

export const dateValidation = {
    required: 'Ce champ est obligatoire',
    valueAsDate: true,
};

export const decimalValidation = {
    required: 'Ce champ est obligatoire',
    pattern: {
        value: /^-?[0-9\s]*[,|.]?[0-9]*$/,
        message: "Ce champ n'accepte que les nombres décimaux",
    },
};

export const confirmPasswordvalidation = {
    validate: (value, formValues) => value === formValues.pass || 'Les mots de passe ne correspondent pas',
};

export const rate5Validation = {
    required: 'Ce champ est obligatoire',
    pattern: {
        value: /^[1-5]$/,
        message: 'La note doit être comprise entre 1 et 5',
    },
};

export const rate10Validation = {
    required: 'Ce champ est obligatoire',
    pattern: {
        value: /^[1-9]|10$/,
        message: 'La note doit être comprise entre 1 et 10',
    },
};

export const defaultValidation = { required: 'Ce champ est obligatoire' };
