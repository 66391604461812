import './Arrow.css';
import React from 'react';
import { ReactComponent as Icon } from '../../../../assets/icons/arrow.svg';

function Arrow({ width = '24px', angle = '0', color = 'white', onClick }) {
    return (
        <Icon
            className="arrow-icon"
            onMouseDown={onClick}
            stroke={color}
            style={{ '--width': width, '--angle': angle, '--width': width }}
        />
    );
}

export default Arrow;
