import React, { useEffect, useRef } from 'react';
import './CaseInput.css';
import { formatNumberWithSpaces, removeSpacesFromEvent, toggleElement } from '../../../../utils/Utils.js';

function CaseInput({
    label,
    name,
    validation = '',
    icon = 'euro',
    visible,
    onChange,
    zeroByDefault = true,
    onFocus,
    disabled,
    onKeyDown,
    useForm,
    value,
    color = 'var(--blue-dark)',
    tip,
}) {
    const inputRegister =
        useForm.register && (visible || visible === undefined) ? useForm.register(name, validation) : null;
    const containerRef = useRef(null);
    const listener = useForm.watch ? useForm.watch(name) : null;

    useEffect(() => {
        if (listener) useForm.setValue(name, formatNumberWithSpaces(listener));
    }, [listener]);

    useEffect(() => {
        if (visible !== undefined) toggleElement(containerRef.current, visible);
    }, [visible]);

    function setZero(e) {
        if (e.target.value === '' && useForm.setValue && zeroByDefault) useForm.setValue(name, 0);
    }

    function resetValue(e) {
        if (e.target.value === '0' && useForm.setValue) useForm.setValue(name, '');
    }

    return (
        <>
            <div className={'w-100 d-flex' + (visible === undefined || visible ? ' pb-sm' : '')} ref={containerRef}>
                <div className="case-input-container">
                    <label className="case-input-label" style={{ '--color': color }} htmlFor={name}>
                        {label} <p className={'label-tip' + (tip ? '' : ' d-none')}>{tip}</p>
                    </label>
                    <span className={'before-' + icon}>
                        <input
                            disabled={(visible !== undefined && !visible) || disabled}
                            id={name}
                            value={value !== undefined ? value : undefined}
                            {...inputRegister}
                            onChange={e => {
                                useForm.setValue && useForm.setValue(name, formatNumberWithSpaces(e.target.value));
                                inputRegister && inputRegister.onChange(removeSpacesFromEvent(e));
                                onChange && onChange(removeSpacesFromEvent(e));
                            }}
                            onFocus={e => {
                                resetValue(e);
                                onFocus && onFocus(e);
                            }}
                            onBlur={setZero}
                            onKeyDown={onKeyDown}
                        />
                    </span>
                </div>
            </div>
        </>
    );
}

export default CaseInput;
