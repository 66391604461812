import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '../assets/fonts/Montserrat/Montserrat-Regular.ttf';
import '../assets/fonts/Montserrat/Montserrat-SemiBold.ttf';
import '../assets/fonts/Montserrat/Montserrat-Medium.ttf';
import '../assets/fonts/Montserrat/Montserrat-Bold.ttf';
import '../stylesheets/main.css';
import Error404 from '../pages/errors/error404/Error404';
import PlanFinancementPage from '../pages/ressources/planFinancementPage/PlanFinancementPage';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/plan-financement" element={<PlanFinancementPage title="Plan de financement" />} />
                <Route path="*" element={<Error404 />} />
            </Routes>
        </Router>
    );
}

export default App;
