import './Warning.css';
import { ReactComponent as Icon } from '../../../../assets/icons/warning.svg';
import React from 'react';

function Warning({ width = '15px', fill = 'var(--red)', onClick }) {
    return (
        <Icon onClick={onClick} style={onClick ? { cursor: 'pointer' } : {}} width={width} height={width} fill={fill} />
    );
}

export default Warning;
