import './Info.css';
import React from 'react';
import { ReactComponent as Icon } from '../../../../assets/icons/info.svg';

function Info({ width = '16px', margin, onClick, color = 'var(--gold)' }) {
    return (
        <div>
            <Icon
                width={width}
                height={width}
                onClick={onClick}
                stroke={color}
                className="icon-info"
                style={{ '--margin': margin }}
            />
        </div>
    );
}

export default Info;
