import React from 'react';
import './ColoredButton.css';
import { Link } from 'react-router-dom';

function ColoredButton({
    children,
    bgColor = 'var(--blue-dark)',
    hoverColor = 'var(--blue-focus)',
    fontSize = '1rem',
    height = '30px',
    padding = '.375rem .75rem',
    onClick,
    borderRadius = '10px',
    to,
    type = 'button',
    className,
}) {
    return (
        <>
            {to ? (
                <Link
                    className={'button color-button ' + className}
                    style={{
                        '--background-color': bgColor,
                        '--hover-color': hoverColor,
                        '--font-size': fontSize,
                        '--height': height,
                        '--padding': padding,
                        '--border-radius': borderRadius,
                    }}
                    onClick={e => {
                        onClick && onClick(e);
                    }}
                    to={to}>
                    {children}
                </Link>
            ) : (
                <button
                    type={type}
                    className={'invisible-button button color-button ' + className}
                    style={{
                        '--background-color': bgColor,
                        '--hover-color': hoverColor,
                        '--font-size': fontSize,
                        '--height': height,
                        '--padding': padding,
                        '--border-radius': borderRadius,
                    }}
                    onClick={e => {
                        onClick && onClick(e);
                    }}>
                    {children}
                </button>
            )}
        </>
    );
}

export default ColoredButton;
