import React from 'react';
import './PaddingButton.css';
import { Link } from 'react-router-dom';

function PaddingButton({
    children,
    bgColor = 'var(--gold)',
    fontSize = '1rem',
    height = '30px',
    padding = '.375rem 1rem',
    className,
    onClick,
    borderRadius = '10px',
    type = 'button',
    to,
}) {
    return (
        <>
            {to ? (
                <Link
                    className={'button padding-button ' + className}
                    onClick={e => {
                        onClick && onClick(e);
                    }}
                    to={to}
                    style={{
                        '--background-color': bgColor,
                        '--font-size': fontSize,
                        '--padding': padding,
                        '--height': height,
                        '--border-radius': borderRadius,
                    }}>
                    {children}
                </Link>
            ) : (
                <button
                    className={'invisible-button button padding-button ' + className}
                    type={type}
                    onClick={e => {
                        onClick && onClick(e);
                    }}
                    style={{
                        '--background-color': bgColor,
                        '--font-size': fontSize,
                        '--padding': padding,
                        '--height': height,
                        '--border-radius': borderRadius,
                    }}>
                    {children}
                </button>
            )}{' '}
        </>
    );
}

export default PaddingButton;
