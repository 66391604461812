import React, { useState } from 'react';
import './Header.css';
import ColoredButton from '../../molecules/buttons/coloredButton/ColoredButton.js';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import Stars from '../../atoms/icons/stars/Stars';
import BurgerMenu from '../../atoms/icons/burgerMenu/BurgerMenu';
import LogoEmc from '../../atoms/icons/logoEmc/LogoEmc';
import Cart from '../../atoms/icons/cart/Cart';
import Power from '../../atoms/icons/power/Power';
import { getIdUser } from '../../../utils/Utils';
import Modal from '../modals/modal/Modal';

function Header() {
    const to = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({
        title: '',
        content: <></>,
    });
    const id_user = getIdUser();

    function displaySubNav(displayed, inc) {
        displayed
            ? document.getElementsByClassName('navbar-content')[inc].classList.remove('d-none')
            : document.getElementsByClassName('navbar-content')[inc].classList.add('d-none');
    }

    function toggleMenu() {
        document.getElementsByClassName('navbar-container')[0].classList.toggle('show');
    }

    const Logout = () => {
        return (
            <div className="modal-buttons">
                <ColoredButton
                    onClick={() => {
                        localStorage.clear('id_user');
                        localStorage.clear('token');
                    }}
                    to="/deconnexion">
                    Je souhaite me déconnecter
                </ColoredButton>
            </div>
        );
    };

    return (
        <>
            <Modal isVisible={modalVisible} title={modalContent.title} setIsVisible={setModalVisible}>
                {modalContent.content}
            </Modal>
            <section className="container my-sm">
                <nav>
                    <NavLink className="logo-container" to="/">
                        <LogoEmc />
                    </NavLink>
                    <ul className="navbar-container show">
                        <li
                            onMouseLeave={() => {
                                displaySubNav(false, 0);
                            }}>
                            <NavLink
                                to="/debut-parcours"
                                className="navbar-header"
                                onMouseEnter={() => {
                                    displaySubNav(true, 0);
                                }}>
                                Estimer
                            </NavLink>
                            <ul className="navbar-content d-none">
                                <NavLink to="/debut-parcours">Fonds de commerce</NavLink>
                                <NavLink to="/debut-parcours">Titres de société</NavLink>
                                <NavLink to="/debut-parcours">Murs commerciaux</NavLink>
                            </ul>
                        </li>
                        <li
                            onMouseLeave={() => {
                                displaySubNav(false, 1);
                            }}>
                            <NavLink
                                to="/conseils-outils"
                                className="navbar-header"
                                onMouseEnter={() => {
                                    displaySubNav(true, 1);
                                }}>
                                <div className="stars-container">
                                    <Stars />
                                </div>
                                Outils
                            </NavLink>
                            <ul className="navbar-content d-none">
                                <NavLink to="/conseils-pros">Conseils de pros</NavLink>
                                <NavLink to="/outils-pratiques">Outils pratiques<br /> à télécharger</NavLink>
                                <NavLink to="/guides-agents-immos">
                                    Nos guides pour agents<br /> immos
                                </NavLink>
                                <NavLink to="/plan-financement">Plan de financement</NavLink>
                                <NavLink to="/simulateur-credit">Simulateur de crédit</NavLink>
                                <NavLink to="/calculateur-cotisation">Calculateur de cotisations</NavLink>
                                <NavLink to="/lexique">Lexique commercial</NavLink>
                                <NavLink to="/faq">F.A.Q.</NavLink>
                            </ul>
                        </li>
                        <NavLink to="/offres">Tarifs</NavLink>
                        <NavLink to="/philosophie">Méthode</NavLink>
                        <NavLink to="/partenaires">Partenaires</NavLink>
                        <li>
                            <div className="navbar-buttons">
                                <div>
                                    <Link className="cart-container" to="/panier">
                                        <Cart />
                                    </Link>
                                    {id_user ? (
                                        <ColoredButton to="/tableau-de-bord" padding="0 2rem">
                                            Mon compte
                                        </ColoredButton>
                                    ) : (
                                        <ColoredButton to="/connexion" padding="0 2rem">
                                            Connexion
                                        </ColoredButton>
                                    )}
                                    {id_user && (
                                        <Power
                                            onClick={() => {
                                                setModalVisible(true);
                                                setModalContent({
                                                    title: 'déconnexion',
                                                    content: <Logout />,
                                                });
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </li>
                    </ul>
                    <BurgerMenu onClick={toggleMenu} />
                </nav>
            </section>
        </>
    );
}

export default Header;
