import React, { useEffect, useState, useContext, forwardRef, useImperativeHandle } from 'react';
import { PlanFinancementContext } from '../planFinancementContext/PlanFinancementContext';
import CaseInput from '../../../../molecules/formComponents/caseInput/CaseInput';
import { useForm } from 'react-hook-form';
import { formatNumberWithSpaces, removeSpaces, uploadImage } from '../../../../../utils/Utils';
import Card from '../../../../molecules/cards/card/Card';
import axios from 'axios';
import ColoredButton from '../../../../molecules/buttons/coloredButton/ColoredButton';
import CardWithHeaders from '../../../../molecules/cards/cardWithHeaders/CardWithHeaders';
import DoubleInput from '../../../../molecules/formComponents/doubleInput/DoubleInput';
import Plus from '../../../../atoms/icons/plus/Plus';
import Warning from '../../../../atoms/icons/warning/Warning';
import Trash from '../../../../atoms/icons/trash/Trash';
import Input from '../../../../molecules/formComponents/input/Input';
import UnfilledButton from '../../../../molecules/buttons/unfilledButton/UnfilledButton';

const PlanFinancementFdc = forwardRef(({ componentRef, visible }, ref) => {
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const Form = { watch, register, setValue };
    const inputsListener = watch();

    const [autresPostes, setAutresPostes] = useState([]);
    const [autresApports, setAutresApports] = useState([]);
    const [tnsFinalRatio, setTnsFinalRatio] = useState(0);
    const [salarieFinalRatio, setSalarieFinalRatio] = useState(0);
    const [tnsRatios, setTnsRatios] = useState([]);
    const [salarieRatios, setSalarieRatios] = useState([]);
    const [nameError, setNameError] = useState('');
    const [autresPostesWarningVisible, setAutresPostesWarningVisible] = useState(false);
    const [autresApportsWarningVisible, setAutresApportsWarningVisible] = useState(false);
    const [checkboxValues, setCheckboxValues] = useState({
        'n-2': false,
        'n-1': false,
        n0: false,
        n1: false,
        moyenne_annees_retraitees: false,
    });
    const [inputsVisible, setInputsVisible] = useState(new Array(11).fill(true));
    const [removeOnPdf, setRemoveOnPdf] = useState(false);

    const {
        id_user,
        typePlan,
        plan,
        setModalVisible,
        setModalContent,
        fetchPlansFinancement,
        showNotification,
        plansFinancement,
        estimationData,
        setLoaderVisible,
    } = useContext(PlanFinancementContext);

    useEffect(() => {
        fetchSalarieRatios();
        fetchTnsRatios();

        setTimeout(() => {
            if (!getValues('total_a_financer')) setValue('total_a_financer', 10000);
            if (!getValues('fonds_roulement')) setValue('fonds_roulement', 10000);
            if (!getValues('taux_emprunt')) setValue('taux_emprunt', 3);
        }, 200);
    }, [visible, plan]);

    useEffect(() => {
        if (typePlan === 0 && Object.keys(plan).length) {
            for (const [key, value] of Object.entries(plan)) {
                if (key !== 'autres') setValue(key, value);
            }

            setAutresPostes(plan.autres.filter(element => element.type === 'postes_fdc'));
            setAutresApports(plan.autres.filter(element => element.type === 'apports_fdc'));
        } else {
            let values = getValues();
            Object.keys(values).forEach(element => setValue(element, ''));
        }
    }, [plan]);

    useEffect(() => {
        function calcTotalAFinancer(inputsListener) {
            let tmp = (({
                prix_vente,
                stocks,
                honoraires_juridiques,
                droits_enregistrement,
                fonds_roulement,
                travaux,
                materiel_agencement,
            }) => ({
                prix_vente,
                stocks,
                honoraires_juridiques,
                droits_enregistrement,
                fonds_roulement,
                travaux,
                materiel_agencement,
            }))(inputsListener);
            tmp = Object.values(tmp);

            let result = Math.round(tmp.reduce((a, b) => removeSpaces(a) + removeSpaces(b)));
            autresPostes.forEach(element => (result += removeSpaces(element.value)));
            if (removeSpaces(getValues('total_a_financer')) !== result) {
                setValue('total_a_financer', result);

                const ratio_apport_financier = removeSpaces(inputsListener.ratio_apport_financier);
                setValue(
                    'apport_financier',
                    result && ratio_apport_financier ? Math.round(result * ratio_apport_financier * 0.01) : '0'
                );
            }
        }

        function calcMontant7Ans(inputsListener) {
            let result = removeSpaces(inputsListener.total_a_financer) - removeSpaces(inputsListener.apport_financier);
            autresApports.forEach(element => {
                result -= removeSpaces(element.value);
            });

            if (result !== removeSpaces(inputsListener.montant_sept_ans))
                setValue('montant_sept_ans', result ? result : '0');
        }

        function calcTotalFinance(inputsListener) {
            let result = removeSpaces(inputsListener.apport_financier) + removeSpaces(inputsListener.montant_sept_ans);
            autresApports.forEach(element => {
                result += removeSpaces(element.value);
            });
            if (result !== removeSpaces(inputsListener.total_finance)) setValue('total_finance', result ? result : '0');
        }

        function calcRbsCredit(inputsListener) {
            let taux_emprunt = removeSpaces(inputsListener.taux_emprunt);
            const montant_sept_ans = removeSpaces(inputsListener.montant_sept_ans);
            if (taux_emprunt && montant_sept_ans) {
                taux_emprunt = taux_emprunt * 0.01;
                let result = Math.round((montant_sept_ans * taux_emprunt) / (1 - Math.pow(1 + taux_emprunt, -7)));
                if (removeSpaces(inputsListener.rbs_credit) !== result) setValue('rbs_credit', result);
            }
        }

        function calcResteAVivre(inputsListener) {
            const result = removeSpaces(inputsListener.ebe_retraite) - removeSpaces(inputsListener.rbs_credit);
            if (result !== removeSpaces(inputsListener.reste_a_vivre)) setValue('reste_a_vivre', result ? result : '0');
        }

        function calcCotSocialesRemNetteTns(inputsListener) {
            if (tnsRatios.length) {
                const resteAVivre = removeSpaces(getValues('reste_a_vivre'));
                let values = {};
                let index = -1;

                for (let i = 0; i < tnsRatios.length; i++) {
                    if (resteAVivre / (1 + tnsRatios[i]['ratio'] / 100) >= tnsRatios[i]['remunerationNette']) {
                        index++;
                    }
                }
                if (index < 0) index = 0;

                const ratio = tnsRatios[index]['ratio'];

                values.rem_nette_tns = resteAVivre / (1 + ratio / 100);
                values.rem_nette_tns_mensuelle = values.rem_nette_tns / 12;
                values.cot_sociales_tns = (values.rem_nette_tns * ratio) / 100;
                if (values.cot_sociales_tns < 0) values.cot_sociales_tns = 0;

                setTnsFinalRatio(Math.round(ratio));

                for (let [key, value] of Object.entries(values)) {
                    value = Math.round(value);
                    if (value !== removeSpaces(inputsListener[key])) setValue(key, value);
                }
            }
        }

        function calcCotSocialesRemNetteSalarie(inputsListener) {
            if (salarieRatios.length) {
                const resteAVivre = removeSpaces(getValues('reste_a_vivre'));
                let values = {};

                const brutAnnuel =
                    resteAVivre /
                    (1 + getPercent(salarieRatios, resteAVivre, 'brutAnnuel', 'ratio_cot_soc_patronales'));
                values.rem_nette_salarie =
                    brutAnnuel / (1 + getPercent(salarieRatios, brutAnnuel, 'brutAnnuel', 'ratio_cot_soc_salarie'));
                values.rem_nette_salarie_mensuelle = values.rem_nette_salarie / 12;
                values.cot_sociales_salarie =
                    resteAVivre -
                    brutAnnuel +
                    values.rem_nette_salarie *
                        getPercent(salarieRatios, values.rem_nette_salarie, 'netAnnuel', 'ratio_cot_soc_salarie');
                if (values.cot_sociales_salarie < 0) values.cot_sociales_salarie = 0;

                setSalarieFinalRatio(Math.round((values.cot_sociales_salarie * 100) / values.rem_nette_salarie));

                for (let [key, value] of Object.entries(values)) {
                    value = Math.round(value);
                    if (value !== removeSpaces(inputsListener[key])) setValue(key, value);
                }
            }
        }

        if (visible) {
            calcTotalAFinancer(inputsListener);
            calcMontant7Ans(inputsListener);
            calcTotalFinance(inputsListener);
            calcRbsCredit(inputsListener);
            calcResteAVivre(inputsListener);
            calcCotSocialesRemNetteSalarie(inputsListener);
            calcCotSocialesRemNetteTns(inputsListener);
        }
    }, [inputsListener]);

    useEffect(() => {
        if (estimationData.extra) {
            const total = removeSpaces(getValues('ebe_retraite'));

            if (total) {
                setEbeCheckboxes(estimationData.extra, total);
            }
        }
    }, [estimationData]);

    useImperativeHandle(ref, () => ({
        setName(value) {
            setValue('name', value);
        },
        resetAutres() {
            setAutresPostes([]);
            setAutresApports([]);
        },
        getName() {
            return getValues('name');
        },
        preparePdf(plan, callback) {
            preparePdf(plan, callback);
        },
    }));

    function fetchTnsRatios() {
        try {
            axios.get(process.env.REACT_APP_SERVER_URL + 'calcul-cotisation/calctns').then(res => {
                let tmp = [];

                for (let i = 0; i < res.data.length; i++) {
                    tmp.push({
                        remunerationNette: res.data[i].rem_nette_tns,
                        ratio: res.data[i].ratio,
                    });
                }
                tmp = setFloor(tmp, 'remunerationNette', 'cotSociales', 'ratio');

                setTnsRatios(tmp);
            });
        } catch (e) {
            showNotification('Une erreur est survenue lors de la récupération de vos données', 'var(--red)');
        }
    }

    function sortRows(a, b) {
        return Number(a.net_annuel) - Number(b.net_annuel);
    }

    function fetchSalarieRatios() {
        try {
            axios.get(process.env.REACT_APP_SERVER_URL + 'calcul-cotisation/calcsalarie').then(res => {
                res.data = res.data.sort(sortRows);
                let tmp = [];
                for (let i = 0; i < res.data.length; i++) {
                    tmp.push({
                        netAnnuel: res.data[i].net_annuel,
                        ratio_cot_soc_salarie: res.data[i].ratio_cot_soc_salarie,
                        ratio_cot_soc_patronales: res.data[i].ratio_cot_soc_patronales,
                    });
                }
                tmp = setFloor(tmp, 'netAnnuel', 'cotSalarieAnnuelles', 'ratio_cot_soc_salarie');
                tmp.forEach(element => {
                    element.brutAnnuel = Number(element.netAnnuel) + Number(element.cotSalarieAnnuelles);
                });
                tmp = setFloor(tmp, 'brutAnnuel', 'cotPatronAnnuelles', 'ratio_cot_soc_patronales');
                tmp.forEach(element => {
                    element.coutEntrepriseAnnuel = Number(element.brutAnnuel) + Number(element.cotPatronAnnuelles);
                });

                tmp = setFloor(tmp, 'netAnnuel', 'netMensuel');
                tmp = setFloor(tmp, 'brutAnnuel', 'brutMensuel');
                tmp = setFloor(tmp, 'coutEntrepriseAnnuel', 'coutEntrepriseMensuel');

                setSalarieRatios(tmp);
            });
        } catch (e) {
            showNotification('Une erreur est survenue lors de la récupération de vos données', 'var(--red)');
        }
    }

    function setFloor(array, fieldRef, field, percentField) {
        array.forEach(element => {
            element[field] = Math.floor(element[fieldRef] * (percentField ? element[percentField] / 100 : 12));
        });
        return array;
    }

    function getPercent(floorsArray, value, refField, percent) {
        let index = -1;
        floorsArray.forEach(element => {
            if (value >= element[refField]) index++;
        });
        return floorsArray[index === -1 ? 0 : index][percent] / 100;
    }

    function addPoste() {
        document.getElementById('autres_postes_label').focus();
        if (getValues('autres_postes_label') !== '') {
            setAutresPostes([
                ...autresPostes,
                {
                    label: getValues('autres_postes_label'),
                    value: getValues('autres_postes_value'),
                    type: 'postes_fdc',
                },
            ]);
            clearPoste();
        } else setAutresPostesWarningVisible(true);
    }

    function clearPoste() {
        setValue('autres_postes_label', '');
        setValue('autres_postes_value', '');
    }

    function deletePoste(index) {
        if (autresPostes.length > 0) setAutresPostes(array => array.filter((el, i) => i !== index));
    }

    function addApport() {
        document.getElementById('autres_apports_label').focus();
        if (getValues('autres_apports_label') !== '') {
            setAutresApports([
                ...autresApports,
                {
                    label: getValues('autres_apports_label'),
                    value: getValues('autres_apports_value'),
                    type: 'apports_fdc',
                },
            ]);
            clearApport();
        } else setAutresApportsWarningVisible(true);
    }

    function clearApport() {
        setValue('autres_apports_label', '');
        setValue('autres_apports_value', '');
    }

    function deleteApport(index) {
        if (autresApports.length > 0) setAutresApports(array => array.filter((el, i) => i !== index));
    }

    function calcHonoraires(e) {
        const prix_vente = e.target.value;
        setValue('honoraires_juridiques', Math.round(prix_vente * 0.03));
    }

    function calcDroitsEnregistrement(e) {
        const prix_vente = e.target.value;
        let result = 0;
        switch (true) {
            case prix_vente >= 23000 && prix_vente < 200000:
                result = (prix_vente - 23000) * 0.03;
                break;
            case prix_vente >= 200000:
                result = 5310 + (prix_vente - 200000) * 0.05;
                break;
        }
        setValue('droits_enregistrement', result);
    }

    function calcRatioFromApportFinancier(e) {
        let apport_financier = e.target.value;
        let result = '0';
        if (removeSpaces(inputsListener.total_a_financer) !== 0)
            result =
                Math.round(
                    (removeSpaces(apport_financier) / removeSpaces(inputsListener.total_a_financer) / 0.01) * 10
                ) / 10;
        if (result !== removeSpaces(inputsListener.ratio_apport_financier)) setValue('ratio_apport_financier', result);
    }

    function calcApportFinancierFromRatio(e) {
        const ratio_apport_financier = e.target.value;
        let result = '0';
        if (removeSpaces(inputsListener.total_a_financer) !== 0)
            result = Math.round(removeSpaces(inputsListener.total_a_financer) * ratio_apport_financier * 0.01);
        if (result !== removeSpaces(inputsListener.apport_financier)) setValue('apport_financier', result);
    }

    function calcEbe(e) {
        const values = {
            ...checkboxValues,
            moyenne_annees_retraitees: false,
            [e.target.id]: e.target.checked,
        };
        let result = 0;
        let divider = 0;
        const checkboxCount =
            document.getElementsByClassName('checkbox-container-plan-financement')[0].children.length / 2;

        if (values.moyenne_annees_retraitees) {
            result = Object.values(estimationData.extra).reduce((a, b) => Number(a) + Number(b), 0);
            divider = Object.keys(estimationData.extra).length;
            Object.keys(values).forEach(key => (key !== 'moyenne_annees_retraitees' ? (values[key] = false) : ''));
        } else {
            const keys = ['n-2', 'n-1', 'n0', 'n1'];
            let allChecked = true;

            keys.forEach(key => {
                if (values[key]) {
                    result += Number(estimationData.extra[key]);
                    divider++;
                } else allChecked = false;
            });

            if (checkboxCount - 1 === divider) allChecked = true;

            if (allChecked) {
                Object.keys(values).forEach(key => (values[key] = false));
                values.moyenne_annees_retraitees = true;
            }
        }
        result && (result = Math.round(result / divider));
        setCheckboxValues(values);

        if (result !== removeSpaces(values.ebe_retraite)) setValue('ebe_retraite', result ? result : '0');
    }

    function setEbeCheckboxes(data, ebe) {
        let values = [];
        Object.values(data).forEach(value => values.push(value));
        const n = values.length;
        const sublists = [];
        let result = {
            'n-2': false,
            'n-1': false,
            n0: false,
            n1: false,
            moyenne_annees_retraitees: false,
        };
        if (values.length === 1 && values[0] === ebe) {
            setCheckboxValues({ ...result, ...data });
            return;
        }
        if (Math.round(values.reduce((a, b) => a + b) / values.length) === ebe) {
            setCheckboxValues({ ...result, moyenne_annees_retraitees: true });
            return;
        }
        for (const [key, value] of Object.entries(data)) {
            if (value === ebe) {
                setCheckboxValues({ ...result, [key]: value });
                return;
            }
        }
        for (let i = 0; i < 1 << n; i++) {
            const sublist = [];
            let sum = 0;
            for (let j = 0; j < n; j++) {
                if ((i & (1 << j)) !== 0) {
                    sublist.push(values[j]);
                    sum += values[j];
                }
            }
            if (sublist.length > 1) {
                const average = Math.round(sum / sublist.length);
                sublists.push({ values: sublist, average });
            }
        }
        const xSublists = sublists.filter(sublist => sublist.average === ebe);

        for (const [key, value] of Object.entries(data)) {
            if (xSublists.length && xSublists[0].values.includes(value)) result[key] = true;
        }

        setCheckboxValues({ ...result });
    }

    async function preparePdf(plan, callback = () => {}) {
        const deletablesEntries = [
            'prix_vente',
            'stocks',
            'honoraires_juridiques',
            'droits_enregistrement',
            'fonds_roulement',
            'travaux',
            'materiel_agencement',
            'ratio_apport_financier',
            'apport_financier',
            'montant_sept_ans',
            'taux_emprunt',
        ];
        let tmp = inputsVisible;
        setLoaderVisible(true);

        for (let i = 0; i < deletablesEntries.length; i++) {
            if (!removeSpaces(getValues(deletablesEntries[i]))) {
                tmp[i] = false;
            }
        }

        document.getElementById('plan-financement-fdc').classList.add('replace-shadows');
        document.getElementsByClassName('plan-financement-div')[0].style.overflowY = '';
        setInputsVisible(tmp);
        setRemoveOnPdf(true);

        setTimeout(() => {
            uploadImage(
                document.getElementById('plan-financement-fdc'),
                localStorage.getItem('token'),
                'plan-financement/image-pdf',
                {
                    id_plan: plan.id,
                    type: 'fdc',
                },
                () => {
                    preparePdfCallback();
                    callback();
                },
                'plan_financement'
            );
        }, 500);
    }

    function preparePdfCallback() {
        document.getElementById('plan-financement-fdc').classList.remove('replace-shadows');
        setInputsVisible(new Array(9).fill(true));
        setRemoveOnPdf(false);

        if (localStorage.getItem('downloadPdf')) {
            setLoaderVisible(false);
            return;
        }

        if (localStorage.getItem('fromSynthese')) {
            localStorage.removeItem('fromSynthese');
            window.location.replace(process.env.REACT_APP_V1_URL + 'rapport-synthetique');
        } else {
            setLoaderVisible(false);
        }
    }

    async function submitForm(values) {
        let data = {};
        const name = values.name;
        const deletablesEntries = [
            'autres_apports_label',
            'autres_apports_value',
            'autres_postes_label',
            'autres_postes_value',
            'rem_nette_salarie_mensuelle',
            'rem_nette_tns_mensuelle',
            'n-2',
            'n-1',
            'n',
            'n+1',
        ];

        for (const [key, value] of Object.entries(values)) {
            data[key] = removeSpaces(value);
        }

        data.moyenne_annees_retraitees = values.moyenne_annees_retraitees;
        data.autres = autresPostes.concat(autresApports);
        data.name = name;

        data.autres.forEach(element => {
            element.value = removeSpaces(element.value);
        });

        deletablesEntries.forEach(element => delete data[element]);
        if (!values.travaux) delete data.travaux;
        if (!values.materiel_agencement) delete data.materiel_agencement;

        if (id_user) {
            if (estimationData.typePlan === 0) data.numEstimation = estimationData.numEstimation;

            try {
                if (Object.keys(plan).length && !localStorage.getItem('planFinancement')) {
                    await axios.put(
                        process.env.REACT_APP_SERVER_URL + 'plan-financement/fdc/' + plan.id,
                        {
                            ...data,
                            id_user: id_user,
                            id: plan.id,
                        },
                        {
                            headers: {
                                Authorization: localStorage.getItem('token'),
                            },
                        }
                    );

                    showNotification('Le plan de financement ' + getValues('name') + ' a été modifié avec succès');

                    setTimeout(() => {
                        document.getElementById('plan-financement-start').scrollIntoView({ behavior: 'smooth' }, true);
                    }, 250);
                } else {
                    if (plansFinancement.filter(element => element.name === values.name).length) {
                        setNameError('Ce nom existe déjà');
                        document
                            .getElementsByName('name')[0]
                            .scrollIntoView({ behavior: 'auto', block: 'center' }, true);
                        return;
                    }
                    await axios.post(
                        process.env.REACT_APP_SERVER_URL + 'plan-financement/fdc',
                        {
                            ...data,
                            id_user: id_user,
                        },
                        {
                            headers: {
                                Authorization: localStorage.getItem('token'),
                            },
                        }
                    );

                    showNotification('Le plan de financement ' + getValues('name') + ' a été créé avec succès');

                    setTimeout(() => {
                        document.getElementById('plan-financement-start').scrollIntoView({ behavior: 'smooth' }, true);
                    }, 250);
                }
                localStorage.removeItem('planFinancement');

                const result = await fetchPlansFinancement(data.name);

                setTimeout(() => {
                    if (estimationData.typePlan === 0) preparePdf(result);
                }, 200);
            } catch (e) {
                showNotification('Une erreur est survenue', 'var(--red)');
            }
        } else {
            setModalVisible(true);
            setModalContent({
                title: 'Inscription',
                content: (
                    <>
                        <div className="modal-text">
                            <p>Vous n'êtes actuellement connecté à aucun compte.</p>
                            <p>
                                Pour continuer votre plan de financement plus tard en conservant vos données déjà
                                saisies, il vous suffit de vous inscrire ou de vous connecter.
                            </p>
                        </div>
                        <div className="modal-buttons">
                            <UnfilledButton
                                to="/inscription?redirection=plan-financement"
                                borderColor="var(--blue-dark)"
                                hoverColor="var(--blue-focus)">
                                Inscription
                            </UnfilledButton>
                            <ColoredButton to="/connexion?redirection=plan-financement">Connexion</ColoredButton>
                        </div>
                    </>
                ),
            });
            localStorage.setItem('planFinancement', JSON.stringify({ ...data, typePlan: 0 }));
        }
    }

    return (
        <div ref={el => (componentRef.current[1] = el)} className={'plan-financement-div' + (visible ? '' : ' d-none')}>
            <form onSubmit={handleSubmit(submitForm)}>
                <Input
                    onChange={() => setNameError('')}
                    name="name"
                    register={register}
                    label="Intitulé du plan de financement"
                    setValue={setValue}
                    error={(errors?.name && errors.name.message) || nameError}
                />
                <div id="plan-financement-fdc">
                    <section className="row-1000 plan-financement-section">
                        <div className="w-100">
                            <CardWithHeaders
                                padding="20px"
                                paddingFooter="10px"
                                header={<h2>Besoins</h2>}
                                footer={
                                    <CaseInput
                                        name="total_a_financer"
                                        bgColor="var(--blue-focus)"
                                        useForm={Form}
                                        label="TOTAL À FINANCER"
                                        icon="euro"
                                        color="white"
                                    />
                                }>
                                <div className="card-content-financement">
                                    <div className={'deletable-input ' + (!inputsVisible[0] && 'd-none')}>
                                        <CaseInput
                                            name="prix_vente"
                                            useForm={Form}
                                            label="Prix du fonds"
                                            icon="euro"
                                            onChange={e => {
                                                calcHonoraires(e);
                                                calcDroitsEnregistrement(e);
                                            }}
                                        />
                                    </div>
                                    <div className={'deletable-input ' + (!inputsVisible[1] && 'd-none')}>
                                        <CaseInput
                                            name="stocks"
                                            useForm={Form}
                                            label="Stocks à reprendre"
                                            icon="euro"
                                        />
                                    </div>
                                    <div className={'deletable-input ' + (!inputsVisible[2] && 'd-none')}>
                                        <CaseInput
                                            name="honoraires_juridiques"
                                            useForm={Form}
                                            label="Honoraires juridiques (en moyenne 3% du prix de vente)"
                                            icon="euro"
                                        />
                                    </div>
                                    <div className={'deletable-input ' + (!inputsVisible[3] && 'd-none')}>
                                        <CaseInput
                                            name="droits_enregistrement"
                                            useForm={Form}
                                            label="Droits d'enregistrement"
                                            icon="euro"
                                        />
                                    </div>
                                    <div className={'deletable-input ' + (!inputsVisible[4] && 'd-none')}>
                                        <CaseInput
                                            name="fonds_roulement"
                                            useForm={Form}
                                            label="Fonds de roulement (trésorerie de démarrage)"
                                            icon="euro"
                                        />
                                    </div>
                                    <div className={'deletable-input ' + (!inputsVisible[5] && 'd-none')}>
                                        <CaseInput name="travaux" useForm={Form} label="Travaux" icon="euro" />
                                    </div>
                                    <div className={'deletable-input ' + (!inputsVisible[6] && 'd-none')}>
                                        <CaseInput
                                            name="materiel_agencement"
                                            useForm={Form}
                                            label="Matériel & agencements"
                                            icon="euro"
                                        />
                                    </div>
                                    {autresPostes.map((element, key) => {
                                        return (
                                            <div className="row-with-button" key={key}>
                                                <CaseInput
                                                    useForm={Form}
                                                    name={'autres_postes.' + key}
                                                    label={element.label}
                                                    value={formatNumberWithSpaces(removeSpaces(element.value))}
                                                    onChange={e =>
                                                        setAutresPostes([
                                                            ...autresPostes.slice(0, key),
                                                            (autresPostes[key] = {
                                                                value: e.target.value,
                                                                label: autresPostes[key].label,
                                                                type: 'postes_fdc',
                                                            }),
                                                            ...autresPostes.slice(key + 1),
                                                        ])
                                                    }
                                                />
                                                <div className={'trash-container ' + (removeOnPdf && 'd-none')}>
                                                    <Trash onClick={() => deletePoste(key)} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div className={removeOnPdf ? 'd-none' : undefined}>
                                        <DoubleInput
                                            placeholder1="Ajouter un poste à financer"
                                            name="autres_postes_value"
                                            name1="autres_postes_label"
                                            useForm={Form}
                                            onKeyDown={e => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    addPoste();
                                                }
                                            }}
                                            onChange1={e => {
                                                if (e.target.value && autresPostesWarningVisible)
                                                    setAutresPostesWarningVisible(false);
                                            }}
                                        />
                                        <div className="row relative">
                                            <div
                                                className={
                                                    'warning-financement' +
                                                    (autresPostesWarningVisible ? '' : ' d-none')
                                                }>
                                                <Warning />
                                                L'intitulé doit être renseigné
                                            </div>
                                            <div className="buttons-container-plan-financement">
                                                <Plus onClick={addPoste} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardWithHeaders>
                        </div>
                        <div className="w-100">
                            <CardWithHeaders
                                className="ressources-card"
                                borderColor="var(--blue-dark-alt)"
                                paddingFooter="10px"
                                padding="20px"
                                header={<h2>Ressources</h2>}
                                footer={
                                    <CaseInput
                                        name="total_finance"
                                        bgColor="var(--blue-focus)"
                                        useForm={Form}
                                        label="TOTAL FINANCÉ"
                                        icon="euro"
                                        color="white"
                                    />
                                }>
                                <div className="card-content-financement">
                                    <div className={'deletable-input ' + (!inputsVisible[7] && 'd-none')}>
                                        <CaseInput
                                            name="ratio_apport_financier"
                                            validation=""
                                            useForm={Form}
                                            label="Apport financier (% du total à financer)"
                                            icon="percent"
                                            error={
                                                errors?.ratio_apport_financier && errors.ratio_apport_financier.message
                                            }
                                            onChange={calcApportFinancierFromRatio}
                                        />
                                    </div>
                                    <div className={'deletable-input ' + (!inputsVisible[8] && 'd-none')}>
                                        <CaseInput
                                            name="apport_financier"
                                            useForm={Form}
                                            label="Montant de l'apport"
                                            icon="euro"
                                            onChange={calcRatioFromApportFinancier}
                                        />
                                    </div>
                                    {autresApports.map((element, key) => {
                                        return (
                                            <div className="row-with-button" key={key}>
                                                <CaseInput
                                                    useForm={{}}
                                                    name={'autres_apports.' + key}
                                                    label={element.label}
                                                    value={formatNumberWithSpaces(removeSpaces(element.value))}
                                                    onChange={e =>
                                                        setAutresApports([
                                                            ...autresApports.slice(0, key),
                                                            (autresApports[key] = {
                                                                value: e.target.value,
                                                                label: autresApports[key].label,
                                                                type: 'apports_fdc',
                                                            }),
                                                            ...autresApports.slice(key + 1),
                                                        ])
                                                    }
                                                />
                                                <div className={'trash-container ' + (removeOnPdf && 'd-none')}>
                                                    <Trash
                                                        color="var(--blue-dark-alt)"
                                                        onClick={() => deleteApport(key)}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div className={removeOnPdf ? 'd-none' : undefined}>
                                        <DoubleInput
                                            placeholder1="Ajouter une source de financement"
                                            name="autres_apports_value"
                                            name1="autres_apports_label"
                                            useForm={Form}
                                            onKeyDown={e => {
                                                if (e.keyCode === 13) {
                                                    e.preventDefault();
                                                    addApport();
                                                }
                                            }}
                                            onChange1={e => {
                                                if (e.target.value && autresApportsWarningVisible)
                                                    setAutresApportsWarningVisible(false);
                                            }}
                                        />
                                        <div className="row">
                                            <div
                                                className={
                                                    'warning-financement' +
                                                    (autresApportsWarningVisible ? '' : ' d-none')
                                                }>
                                                <Warning />
                                                L'intitulé doit être renseigné
                                            </div>
                                            <div className="buttons-container-plan-financement">
                                                <Plus color="var(--blue-dark-alt)" onClick={addApport} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'deletable-input ' + (!inputsVisible[9] && 'd-none')}>
                                        <CaseInput
                                            name="montant_sept_ans"
                                            useForm={Form}
                                            label="Montant à emprunter sur 7 ans"
                                            icon="euro"
                                        />
                                    </div>
                                    <div className={'deletable-input ' + (!inputsVisible[10] && 'd-none')}>
                                        <CaseInput
                                            name="taux_emprunt"
                                            useForm={Form}
                                            label="Taux d'emprunt (TAEG)"
                                            icon="percent"
                                        />
                                    </div>
                                </div>
                            </CardWithHeaders>
                        </div>
                    </section>
                    <section className="plan-financement-section">
                        <Card padding="30px" bgColor="var(--blue-focus)">
                            <h2>
                                La rentabilité annuelle dégagée par l'affaire&nbsp;
                                <span className="lowercase">(sans prendre en compte de la situation du dirigeant)</span>
                            </h2>
                        </Card>
                        <div className="row p-md relative ebe-container">
                            <CaseInput
                                name="ebe_retraite"
                                useForm={Form}
                                onChange={e => {
                                    if (estimationData.extra) setEbeCheckboxes(estimationData.extra, e.target.value);
                                }}
                                label="EBE retraité (avant cotisations de l'exploitant)"
                                icon="euro"
                                tip={
                                    estimationData.typePlan === 0 && !removeOnPdf
                                        ? 'Vous pouvez remplir le champ OU cocher les cases ci-contre'
                                        : ''
                                }
                            />
                            <div className="checkbox-container-plan-financement">
                                {estimationData.extra && estimationData.extra['n-2'] && !removeOnPdf && (
                                    <>
                                        <input
                                            type="checkbox"
                                            id="n-2"
                                            className="checkbox-plan-financement"
                                            checked={checkboxValues['n-2']}
                                            onChange={calcEbe}
                                        />
                                        <label className="checkbox-label-plan-financement" htmlFor="n-2">
                                            N - 2
                                        </label>
                                    </>
                                )}
                                {estimationData.extra && estimationData.extra['n-1'] && !removeOnPdf && (
                                    <>
                                        <input
                                            type="checkbox"
                                            id="n-1"
                                            className="checkbox-plan-financement"
                                            checked={checkboxValues['n-1']}
                                            onChange={calcEbe}
                                        />
                                        <label className="checkbox-label-plan-financement" htmlFor="n-1">
                                            N - 1
                                        </label>
                                    </>
                                )}
                                {estimationData.extra && estimationData.extra['n0'] && !removeOnPdf && (
                                    <>
                                        <input
                                            type="checkbox"
                                            id="n0"
                                            className="checkbox-plan-financement"
                                            checked={checkboxValues['n0']}
                                            onChange={calcEbe}
                                        />
                                        <label className="checkbox-label-plan-financement" htmlFor="n0">
                                            N
                                        </label>
                                    </>
                                )}
                                {estimationData.extra && estimationData.extra['n1'] && !removeOnPdf && (
                                    <>
                                        <input
                                            type="checkbox"
                                            id="n1"
                                            checked={checkboxValues['n1']}
                                            className="checkbox-plan-financement"
                                            onChange={calcEbe}
                                        />
                                        <label className="checkbox-label-plan-financement" htmlFor="n1">
                                            N + 1
                                        </label>
                                    </>
                                )}
                                {estimationData.extra &&
                                    Object.keys(estimationData.extra).length > 1 &&
                                    !removeOnPdf && (
                                        <>
                                            <input
                                                type="checkbox"
                                                id="moyenne_annees_retraitees"
                                                className="checkbox-plan-financement"
                                                checked={checkboxValues['moyenne_annees_retraitees']}
                                                onChange={calcEbe}
                                            />
                                            <label
                                                className="checkbox-label-plan-financement"
                                                htmlFor="moyenne_annees_retraitees">
                                                Moyenne des années renseignées
                                            </label>
                                        </>
                                    )}
                            </div>
                        </div>
                        <Card padding="30px" bgColor="var(--blue-sky)">
                            <h2>...Sert à rembourser le prêt sur l'achat du fonds de commerce...</h2>
                        </Card>
                        <div className="row p-md">
                            <CaseInput
                                name="rbs_credit"
                                useForm={Form}
                                label="Remboursement annuel du crédit (annuité capital + intérêts)"
                                icon="euro"
                            />
                        </div>
                        <Card padding="30px" bgColor="var(--blue-dark)">
                            <h2>
                                ...Et permet au repreneur de se rémunérer, payer sa protection sociale et ses impôts
                            </h2>
                        </Card>
                        <div className="row p-md">
                            <CaseInput
                                name="reste_a_vivre"
                                useForm={Form}
                                label="Reste à vivre annuel avant imposition"
                                icon="euro"
                            />
                        </div>
                        <Card padding="30px" bgColor="#f1e9d0">
                            <h2 className='blue-dark-text'>
                                Simulations de revenus annuels nets après cotisations sociales&nbsp;
                                <span className="lowercase blue-dark-text">(à titre indicatif)</span>
                            </h2>
                        </Card>
                        <div className="row-1000 py-lg gap-md">
                            <div className="col">
                                <h3 className="h3-plan-financement mb-sm">Repreneur en statut TNS à l'IS</h3>
                                <Card bgColor="#f1e9d0" padding="25px 20px">
                                    <CaseInput
                                        name="cot_sociales_tns"
                                        useForm={Form}
                                        label={`Cotisations sociales annuelles (${tnsFinalRatio}%)`}
                                        icon="euro"
                                    />
                                    <CaseInput
                                        name="rem_nette_tns"
                                        validation=""
                                        useForm={Form}
                                        label="Rémunération annuelle nette avant imposition personnelle"
                                        icon="euro"
                                    />
                                    <CaseInput
                                        name="rem_nette_tns_mensuelle"
                                        validation=""
                                        useForm={Form}
                                        label="Soit une rémunération mensuelle nette de "
                                        icon="euro"
                                    />
                                </Card>
                            </div>
                            <div className="col">
                                <h3 className="h3-plan-financement mb-sm">Repreneur en statut salarié</h3>
                                <Card bgColor="#f1e9d0" padding="25px 20px">
                                    <CaseInput
                                        name="cot_sociales_salarie"
                                        useForm={Form}
                                        label={`Cotisations sociales annuelles (${salarieFinalRatio}%)`}
                                        icon="euro"
                                    />
                                    <CaseInput
                                        name="rem_nette_salarie"
                                        useForm={Form}
                                        label="Rémunération annuelle nette avant imposition personnelle"
                                        icon="euro"
                                    />
                                    <CaseInput
                                        name="rem_nette_salarie_mensuelle"
                                        validation=""
                                        useForm={Form}
                                        label="Soit une rémunération mensuelle nette de "
                                        icon="euro"
                                    />
                                </Card>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="centered">
                    <ColoredButton
                        type="submit"
                        height="50px"
                        bgColor="var(--blue-focus)"
                        hoverColor="var(--blue-dark)">
                        Valider et sauvegarder mes données
                    </ColoredButton>
                </div>
            </form>
        </div>
    );
});

export default PlanFinancementFdc;
